import { Icon, PropsOf } from "@chakra-ui/react";
import type { FunctionComponent } from "react";

export const DukeIcon: FunctionComponent<PropsOf<typeof Icon>> = (props) => {
  return (
    <Icon
      {...props}
      aria-hidden="true"
      enableBackground="new 0 0 612 792"
      viewBox="0 0 612 792"
    >
      <path d="M296.762,168.615c0,0-49,93-73,152s-50,125-59,163s-22.31,100.002-21,120c1.143,17.454,1.205,20.431,9.204,32.432 c4.795,7.193,11.988,11.09,22.796,11.568c12.987,0.575,32.587-10.781,46-18c14.222-7.654,29.807-20.542,52-23 c23.875-2.645,36-4,56,2s30.6,9.75,41,17c11.66,8.128,12.078,10.732,20.18,17.014c9.682,7.505,15.1,8.682,25.291,9.06 s18.117-2.265,24.535-12.456c6.416-10.191,11.004-26.263,12.803-40.049c1.617-12.386,0.031-56.205-6.764-87.157 s-5.66-25.289-12.455-53.6s-18.496-68.699-40.012-119.279s-27.555-63.415-44.541-97.764s-34.727-62.282-39.256-69.831 C306.012,164.003,302.424,156.914,296.762,168.615z" />

      <path
        d="M396.215,382.896c-40.479-3.615-35.811-2.775-80.758-1.937c-50.206,0.937-103.914,9.39-103.914,9.39 c-14.784,38.199-27.105,72.67-32.769,96.584c-12.759,53.872-20.709,97.565-20.709,113.463c0,0.837,0.022,1.598,0.066,2.277 l0.061,0.933c1.038,15.874,1.038,15.874,6.756,24.454c1.549,2.324,3.839,4.832,11.45,5.169c7.885,0.349,25.481-9.208,34.934-14.343 l3.604-1.951c2.974-1.602,6.176-3.556,9.566-5.625c12.241-7.472,27.475-16.771,47.673-19.008c24.326-2.694,38.944-4.313,61.723,2.52 c18.949,5.686,32.326,10.077,45.096,18.979c7.795,5.434,11.367,8.884,14.52,11.929c1.893,1.828,3.527,3.407,6.25,5.519 c7.027,5.448,9.502,5.771,17,6.05c6.367,0.235,8.797-0.944,11.816-5.739c4.709-7.48,9.014-21.24,10.709-34.239 c0.289-2.214,0.451-6.104,0.451-11.147c0-17.17-1.875-47.714-7-71.059c-5.059-23.039-5.654-25.494-8.52-37.294l-3.873-16.033 C414.064,435.604,406.424,410.398,396.215,382.896z"
        fill="#FFFFFF"
      />

      <path d="M194.019,410.938c0,0-6.715,3.778-14.27,10.073s-8.395,8.813-19.727,15.109s-24.763,13.012-35.676,20.986 s-18.888,13.851-20.566,15.949s-3.357,3.778-0.839,10.493s1.679,5.456,6.296,13.851s14.27,21.405,14.27,21.405 s-1.678,0.841-1.259,7.136s2.099,9.232,5.037,10.492s0.42,4.618-0.839,11.333s-0.42,11.752-0.42,11.752s-8.394,0-13.851,1.679 s-9.653,1.678-9.234,7.135s0.42,7.555,7.975,12.172s5.456,5.036,13.011,6.296s15.11,4.616,20.566-0.42s14.27-64.217,14.27-64.217 s-5.456-7.975-8.394-10.073s-8.394-5.037-10.073-3.777s-5.457,2.938-5.457,2.938s-14.27-22.245-16.789-26.862 s-5.036-4.196-1.679-7.135s7.555-7.976,15.11-13.432s16.788-11.331,22.245-14.689s11.752-6.716,11.752-6.716 s-1.259-2.518,2.099,4.617s6.296,14.69,7.975,15.949s13.431-38.194,13.431-38.194L194.019,410.938z" />

      <path d="M410.174,399.186c0,0,9.234,2.098,16.369,7.975c7.135,5.876,8.395,4.195,12.172,10.072s5.037,7.556,5.037,7.556 s24.762,18.887,31.898,25.183c7.135,6.296,17.207,14.69,22.244,20.986s8.395,9.654,6.297,14.271 c-2.1,4.616-7.975,10.912-11.752,15.109s-11.332,12.592-11.332,12.592s1.678,6.717,0.418,13.012 c-1.258,6.295-3.357,10.912-3.357,10.912s6.297,4.616,7.975,11.332c1.68,6.716,1.68,13.852,1.68,13.852s9.654,0.839,13.432,3.357 s5.875,4.617,2.518,8.395s-10.072,9.654-16.369,14.271c-6.295,4.616-7.135,7.135-12.592,9.653c-5.455,2.519-7.135,1.678-13.012-0.84 c-5.875-2.518-10.912-5.876-10.912-5.876l-9.654-73.45l12.592-0.42c0,0-1.258-4.196,3.359-7.555 c4.615-3.358,10.912-2.939,13.01-3.358c2.1-0.419,4.199-6.716,8.814-12.172c4.617-5.456,9.234-7.974,9.234-10.912 s-5.037-11.332-7.135-14.271c-2.1-2.938-17.629-18.888-20.986-22.245s-16.369-11.752-16.369-11.752s0.42-0.001-2.938,9.233 c-3.359,9.234-7.555,16.37-10.074,19.308c-2.518,2.938-22.244-64.217-22.244-64.217" />

      <ellipse
        cx="307.648"
        cy="385.404"
        fill="#B30838"
        rx="74.43"
        ry="65.268"
        stroke="#000000"
        strokeWidth="16"
      />

      <ellipse
        cx="276.734"
        cy="358.447"
        fill="#FFFFFF"
        rx="19.849"
        ry="13.741"
        transform="matrix(0.9225 -0.386 0.386 0.9225 -116.9202 134.6115)"
      />
    </Icon>
  );
};
